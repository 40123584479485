import React, { useState, useEffect, useRef } from "react";
import {
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import { Plus, Play, Tag, X } from "react-feather";
import axios from "axios";
import { toast } from "react-toastify";
import SuggestionsDropdown from "./SuggestionsDropdown";
import { userFuzzySearch } from "../services/postService";

let signal;

const SplitEarning = ({ addSplitUsers, price, isPosting }) => {
  const [splitUsers, setSplitUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [splitPercent, setSplitPercent] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const modelNameInputRef = useRef(null);

  useEffect(() => {
    let timeout = null;
    if (query && !selectedUser) {
      setIsLoadingSuggestions(true);
      timeout = setTimeout(() => getListOfModels(), 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      //remove selected user if user types in the input
      if (selectedUser) {
        setSelectedUser(null);
      }
    };
  }, [query, selectedUser]); // eslint-disable-line

  useEffect(() => {
    addSplitUsers(splitUsers);
  }, [splitUsers]); // eslint-disable-line

  const getListOfModels = async () => {
    setIsLoadingSuggestions(true);
    const SIGNAL_CANCEL_MESSAGE = "signal_cancelled";
    if (signal) {
      signal.cancel(SIGNAL_CANCEL_MESSAGE);
    }
    signal = axios.CancelToken.source();

    try {
      const response = await userFuzzySearch(
        { search: query, isModel: true },
        {
          cancelToken: signal.token,
        }
      );
      signal = undefined;
      if (response.data.data) {
        setUserSuggestions(response.data.data);
      } else {
        setUserSuggestions([]);
      }
      setIsLoadingSuggestions(false);
    } catch (err) {
      if (!!err.message && err.message !== SIGNAL_CANCEL_MESSAGE) {
        setUserSuggestions([]);
        setIsLoadingSuggestions(false);
      }
    }
  };

  const clickSuggestedUserHandler = (user) => {
    const ifSelectedUserAlready = splitUsers.some(
      (splitUser) => splitUser.id === user.id
    );

    if (ifSelectedUserAlready) {
      showToast("You have already selected this user");
      setQuery("");
      setUserSuggestions([]);
      return;
    }
    const { id, nickName, username } = user;
    const selectedUser = {
      id,
      nickName,
      username,
    };
    setSelectedUser(selectedUser);
    setQuery(nickName ?? username);
    setUserSuggestions([]);
  };

  const showToast = (message) => {
    toast.warn(message, {
      toastId: message,
    });
  };

  const validateData = () => {
    if (!price) {
      showToast("Please enter a price for the locked post");
      return true;
    }

    if (!selectedUser) {
      showToast("Please select a model to split your earnings with");
      modelNameInputRef.current.focus();
      setQuery("");
      return true;
    }

    if (!splitPercent) {
      showToast(
        "Please enter a percentage of the price you would like to split"
      );
      return true;
    }

    if (+splitPercent <= 0) {
      showToast("Percentage should be greater than 0");
      return true;
    }

    if (+splitPercent > 100) {
      showToast("Percentage cannot be greater than 100");
      return true;
    }
    //validation so that % is not > 100
    const percentOfSplitUsers = splitUsers.reduce(
      (acc, cur) => acc + +cur.percent,
      0
    );

    if (percentOfSplitUsers + +splitPercent > 100) {
      showToast("Total percentage cannot be greater than 100");
      return true;
    }
    return false;
  };

  const addSplitUser = () => {
    const isError = validateData();
    if (isError) return;
    setSplitUsers([
      ...splitUsers,
      { ...selectedUser, percent: splitPercent.replace(/^0+/, "") },
    ]);
    setQuery("");
    setSplitPercent("");
    setSelectedUser(null);
  };

  const removeSelectedUser = (id) => {
    const filteredUsers = splitUsers.filter((user) => user.id !== id);
    setSplitUsers(filteredUsers);
  };

  return (
    <div className="tagging-box-outer">
      <div className="title">
        <p>
          <Play />
          To "Split Earnings" between all co-performers
        </p>
      </div>
      <div className="tagging-input-field-outer">
        <div className="holder">
          <div className="tagging-input-field">
            <div className="form-label-group">
              <div className="tagging-icon">
                <Tag />
              </div>
              <Input
                type="text"
                name="text"
                value={query}
                placeholder="Enter co-performer's name"
                innerRef={modelNameInputRef}
                onChange={(e) => setQuery(e.target.value)}
                autoComplete="off"
                disabled={isPosting}
              />
              {query && (
                <SuggestionsDropdown
                  query={query}
                  users={userSuggestions}
                  isLoading={isLoadingSuggestions}
                  clickSuggestedUserHandler={clickSuggestedUserHandler}
                  setUserSuggestions={setUserSuggestions}
                />
              )}
            </div>
          </div>
          <div className="tagging-payment-field">
            <FormGroup className="form-label-group position-relative has-icon-left">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {/* <InputGroupText>$</InputGroupText> */}
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  min="0"
                  placeholder="00"
                  value={splitPercent}
                  disabled={isPosting}
                  onChange={(e) => {
                    if (e.target.value > 100) return;
                    setSplitPercent(e.target.value);
                  }}
                />
                {/* <InputGroupAddon addonType="append">%</InputGroupAddon> */}
                <Button
                  className="add-button"
                  onClick={addSplitUser}
                  disabled={isPosting}
                >
                  <Plus />
                </Button>
              </InputGroup>
            </FormGroup>
          </div>
        </div>
        {price && splitPercent && (
          <div className="percent-in-dollars">
            <small>
              You will be giving{" "}
              {selectedUser
                ? selectedUser.nickName ?? selectedUser.username
                : ""}{" "}
              ${(+price * (splitPercent / 100)).toFixed(2)} per post unlocked
            </small>
          </div>
        )}

        {splitUsers.length > 0 && (
          <div className="tagging-list">
            {splitUsers.map((user) => (
              <p key={user.id}>
                {user.nickName ?? user.username}{" "}
                <span
                  className="tagging-value"
                  style={{
                    background: "#d4af37",
                    borderRadius: "30px",
                    padding: "0px 5px",
                  }}
                >
                  {user.percent}%
                </span>
                {!isPosting && (
                  <span
                    className="cancel-tagging"
                    onClick={() => removeSelectedUser(user.id)}
                  >
                    <X />
                  </span>
                )}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitEarning;
