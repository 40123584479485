import React from "react";
import { Button, Spinner, Modal, ModalBody } from "reactstrap";

const ConfirmationModal = ({
  message,
  buttonText,
  isPerformingAction,
  isModalOpen,
  closeModal,
  action,
  darkMode = false,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      centered={true}
      toggle={closeModal}
      className={`live-stream-modal update-vod-details-modal vod-appeal-modal ${
        darkMode ? "confirmation-modal-dark" : ""
      }`}
    >
      <div className="modal-close-btn" onClick={closeModal}>
        <span aria-hidden="true">×</span>
      </div>
      <ModalBody>
        <h3 className="text-center mt-2 mb-2">{message}</h3>
        <div className="submit-btn-group">
          <Button.Ripple
            disabled={isPerformingAction}
            color="primary"
            onClick={action}
          >
            {isPerformingAction ? <Spinner size="sm" /> : buttonText}
          </Button.Ripple>
          <Button
            color="secondary"
            id="confirmation-modal-cancel-btn"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
