import { fetchLiveUsers } from "../../../services/muxService";

export const SET_IS_USER_LIVE = "SET_IS_USER_LIVE";
export const SET_LIVE_MODELS = "SET_LIVE_MODELS";
export const ADD_LIVE_MODEL = "ADD_LIVE_MODEL";
export const REMOVE_LIVE_USER = "REMOVE_LIVE_USER";
export const SET_IS_USER_LIVE_FROM_THIS_SESSION =
  "SET_IS_USER_LIVE_FROM_THIS_SESSION";

export const SetIsUserLiveAction = (value) => {
  return {
    type: SET_IS_USER_LIVE,
    value,
  };
};

export const SetIsUserLiveFromThisSessionAction = (value) => {
  return {
    type: SET_IS_USER_LIVE_FROM_THIS_SESSION,
    value,
  };
};

export const SetLiveModelsAction = (value) => {
  return {
    type: SET_LIVE_MODELS,
    value,
  };
};

export const AddLiveModelAction = (value) => {
  return {
    type: ADD_LIVE_MODEL,
    value,
  };
};

export const RemoveLiveUserAction = (value) => {
  return {
    type: REMOVE_LIVE_USER,
    value,
  };
};

export const GetLiveModelsAction = (dispatch) => {
  return async () => {
    try {
      const response = await fetchLiveUsers();

      if (response.data.statusCode === 200) {
        dispatch(SetLiveModelsAction(response.data.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
