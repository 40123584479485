export const baseURL = process.env.REACT_APP_APIS;
export const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
export const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
export const moderatorPermissionMap = {
  "Write New Post": "writePost",
  "Read Post": "readPost",
  "Delete Existing Post": "deletePost",
  "Like / Unlike Post": "likePost",
  "Comment On Post": "commentPost",
};

export const tipTypes = {
  post: "PostTip",
  chat: "ChatTip",
  live: "LiveStreamTip",
};

export const orderTypeFormat = (orderType) => {
  switch (orderType) {
    case "UnLockedPost":
      return "Locked Post";
    case "BuyShop":
      return "VOD";
    case "BuyMessage":
      return "Chat Media";
    case "PostTip":
      return "Post Tip";
    case "LiveStreamTip":
      return "Live Stream Tip";
    case "ChatTip":
      return "Chat Tip";
    case "referral":
      return "Referral";
    case "EventTicket":
      return "Event Ticket";
    case "EventBonus":
      return "Event Bonus";
    default:
      return orderType;
  }
};

export const landingPageFormTypes = {
  login: "login",
  register: "register",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
};

export const liveStreamingMessageTypes = {
  text: "text",
  like: "like",
  tagCount: "tagCount",
};

export const purchaseSignalType = "premium-message-purchase";

export const bellNotificationTypeToNameMap = {
  Rejected: "Rejected",
  Live: "Live",
  subscription: "Subscription",
  follow: "Follow",
  tip: "Tip",
  unsubscribe: "Unsubscribe",
  subscriptionCreate: "Subscription",
  subcription: "Subscription",
  Approved: "Post Approved",
  comment: "Comment",
  like: "Like",
  "Release Form": "Release Form",
  tag: "Tag",
  paidPost: "Paid Post Available",
  unlockedPost: "Post Unlocked",
  verificationRequest: "Verification",
  chat: "Message",
  ticketUpdate: "Ticket",
  refundStatusUpdate: "Refund",
  refundRequest: "Refund",
  "shop create": "VOD available",
  buyShop: "VOD",
  vodApproved: "VOD Approved",
  vodRejected: "VOD Rejected",
  newRegister: "Welcome",
  forgotpassword: "Password",
  passwordChanged: "Password",
  becomeModel: "Model Request Accepted",
  withdrawRequest: "Withdraw",
  withdrawalStatusUpdate: "Withdraw",
  Verified: "Verified",
  rejectFan: "Verification Rejected",
  rejectModel: "Model Request Rejected",
  "From Feature Fan Authority": "From Feature Fan Authority",
  withdrawStatusUpdate: "Withdraw Status Update",
  "new-event-create": "New Event Created",
  "event-ticket-purchased": "Event Purchased",
  "new-tagged-in-any-event": "Tagged In Event",
  "event-tag-accept": "Event Invitation Accepted",
  "event-tag-rejected": "Event Invitation Rejected",
  "event-reminder": "Event Reminder",
};

export const formatRefundStatus = (refundStatus) => {
  switch (refundStatus) {
    case "InReview":
      return "In Review";
    default:
      return refundStatus;
  }
};

export const timestamp = "1706210005407";

export const timelineMap = {
  feed: "Feed",
  premium: "Premium",
  archive: "Archive",
};

export const postTypesMap = {
  fan: "Fan",
  locked: "Locked",
  subscription: "Subscription",
};

export const postStatusMap = {
  active: "Active",
  pending: "Pending",
  rejected: "Rejected",
  scheduled: "Scheduled",
};

export const VOD_PRICE_FILTER_LOWER_LIMIT = 0;
export const VOD_PRICE_FILTER_UPPER_LIMIT = 200;

export const transactionsFilterOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "UnLockedPost",
    label: "Locked Post",
  },
  {
    value: "Subscription",
    label: "Subscription",
  },
  {
    value: "BuyShop",
    label: "VOD",
  },
  {
    value: "LiveStream",
    label: "Live Stream",
  },
  {
    value: "LiveStreamTip",
    label: "Live Stream Tip",
  },
  {
    value: "PostTip",
    label: "Post Tip",
  },
  {
    value: "ChatTip",
    label: "Chat Tip",
  },
  {
    value: "BuyMessage",
    label: "Premium Chat",
  },
  {
    value: "ReferralEarning",
    label: "Referral",
  },
  {
    value: "EventTicket",
    label: "Event Ticket",
  },
];

export const earningFilterOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "UnLockedPost",
    label: "Locked Post",
  },
  {
    value: "Subscription",
    label: "Subscription",
  },
  {
    value: "BuyShop",
    label: "VOD",
  },
  {
    value: "LiveStream",
    label: "Live Stream",
  },
  {
    value: "LiveStreamTip",
    label: "Live Stream Tip",
  },
  {
    value: "PostTip",
    label: "Post Tip",
  },
  {
    value: "ChatTip",
    label: "Chat Tip",
  },
  {
    value: "BuyMessage",
    label: "Premium Chat",
  },
  {
    value: "ReferralEarning",
    label: "Referral",
  },
  {
    value: "EventTicket",
    label: "Event Ticket",
  },
  {
    value: "EventBonus",
    label: "Event Bonus",
  },
];
