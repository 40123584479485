import { store } from "../redux/storeConfig/store";

export default function hasFlaggedKeywords(text) {
  const flaggedKeywords = store.getState()?.app?.flaggedKeywords;
  if (!flaggedKeywords || !text) {
    return false;
  }

  const textIntoArray = text.toLowerCase().replaceAll("\n", "").split(" ");

  return flaggedKeywords.some((word) => textIntoArray.indexOf(word) !== -1);
}
