import {
  SET_IS_USER_LIVE,
  SET_LIVE_MODELS,
  ADD_LIVE_MODEL,
  REMOVE_LIVE_USER,
  SET_IS_USER_LIVE_FROM_THIS_SESSION,
} from "../../actions/liveActions/liveActions";

export const liveInitialState = {
  isUserLive: false,
  isUserLiveFromThisSession: false,
  liveModels: [],
};

const live = (state = liveInitialState, action) => {
  switch (action.type) {
    case SET_IS_USER_LIVE:
      return {
        ...state,
        isUserLive: action.value,
      };
    case SET_IS_USER_LIVE_FROM_THIS_SESSION:
      return {
        ...state,
        isUserLiveFromThisSession: action.value,
      };
    case SET_LIVE_MODELS:
      return {
        ...state,
        liveModels: action.value,
      };
    case ADD_LIVE_MODEL:
      const filteredLiveUsers = state.liveModels.filter(
        (model) => model.user.id !== action.value.user.id
      );
      return {
        ...state,
        liveModels: [action.value, ...filteredLiveUsers],
      };
    case REMOVE_LIVE_USER:
      const updatedLiveUsers = state.liveModels.filter(
        (model) => model.user.id !== action.value
      );
      return {
        ...state,
        liveModels: updatedLiveUsers,
      };
    default:
      return state;
  }
};
export default live;
