export const taggingIntersection = (description, taggedUsersParam) => {
  const taggedUsers = []; //Stores actual tagged users

  const taggedUsersInDescription = description
    .split("@")
    .map((word) => word.split(" ")[0]);

  const taggedUsernames = taggedUsersParam.map((user) => user.username);

  //intersection are the usernames that are in description and in tagged users state
  //This logic is for if you tag someone and use backspace afterwards to remove user
  const intersection = [];

  taggedUsersInDescription.forEach((username) => {
    const taggedUserExact = taggedUsernames.find((user) => username === user);
    if (taggedUserExact) {
      const isAlreadyTagged = intersection.some(
        (username) => username === taggedUserExact
      );
      if (!isAlreadyTagged) {
        intersection.push(taggedUserExact);
      }
    } else {
      const taggedUser = taggedUsernames.find((user) =>
        username.includes(user)
      );

      const isAlreadyTagged = intersection.some(
        (username) => username === taggedUser
      );
      if (!isAlreadyTagged) {
        intersection.push(taggedUser);
      }
    }
  });

  //logic to map users to the usernames got in the intersection
  intersection.forEach((taggedUser) => {
    const userFound = taggedUsersParam.find(
      (user) => user.username === taggedUser
    );
    if (userFound) {
      taggedUsers.push(userFound);
    }
  });

  return taggedUsers;
};
