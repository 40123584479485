import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const createEvent = (payload) => {
  return serviceClient.post("v1/event/create", payload);
};

export const listEvents = (payload) => {
  return serviceClient.get("v1/event/list", {
    params: payload,
  });
};

export const buyEvent = (payload) => {
  return serviceClient.post("v1/event/purchase", payload);
};

export const updateInvitationStatus = (payload) => {
  return serviceClient.post("v1/event/tagged/request", payload);
};
