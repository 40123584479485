export const SET_IS_CAMERA_MUTED = "SET_IS_CAMERA_MUTED";
export const SET_IS_MICROPHONE_MUTED = "SET_IS_MICROPHONE_MUTED";
export const SET_MICROPHONE_DEVICE_ID = "SET_MICROPHONE_DEVICE_ID";
export const SET_IS_USING_MICROPHONE = "SET_IS_USING_MICROPHONE";
export const SET_ACTIVE_MUSIC_TRACK = "SET_ACTIVE_MUSIC_TRACK";
export const SET_CAMERA_ASPECT_RATIO = "SET_CAMERA_ASPECT_RATIO";

export const SetIsCameraMutedAction = (value) => {
  return {
    type: SET_IS_CAMERA_MUTED,
    value,
  };
};

export const SetIsMicrophoneMutedAction = (value) => {
  return {
    type: SET_IS_MICROPHONE_MUTED,
    value,
  };
};

export const SetMicrophoneDeviceIdAction = (value) => {
  return {
    type: SET_MICROPHONE_DEVICE_ID,
    value,
  };
};

export const SetIsUsingMicrophoneAction = (value) => {
  return {
    type: SET_IS_USING_MICROPHONE,
    value,
  };
};

export const SetActiveMusicTrackAction = (value) => {
  return {
    type: SET_ACTIVE_MUSIC_TRACK,
    value,
  };
};

export const SetCameraAspectRatio = (value) => {
  return {
    type: SET_CAMERA_ASPECT_RATIO,
    value,
  };
};
